<template>
  <div class="noscrollmain">
    <common-top
      title="积分榜单"
      :isShowSearch="false"
      @search="showModal = true"
      :isFilter="isFilter"
      :sortList="sortList"
      @sortData="sortData"
    ></common-top>

    <Modal v-model="showDetailStatus" fullscreen>
      <p slot="header" style="text-align: center">
        <span>{{ detailInfo["单位"] }}</span>
      </p>
      <div class="commonShadow">
        <div
          style="
            padding: 10px 0;
            text-align: center;
            width: 98%;
            margin: 0 auto;
          "
        >
          总完整率
        </div>
        <pie-chart
          v-if="showDetailStatus"
          :id="`deptUserFollow`"
          :chartSytle="`width:90vw; height: 300px`"
          :option="deptUserOption"
        ></pie-chart>
      </div>
      <div class="commonShadow">
        <div
          style="
            padding: 10px 0;
            text-align: center;
            width: 98%;
            margin: 0 auto;
          "
        >
          录入数据
        </div>
        <pie-chart
          v-if="showDetailStatus"
          :id="`deptUserFollowRate`"
          :chartSytle="`width:90vw; height: 300px`"
          :option="dataNumOption"
        ></pie-chart>
      </div>

      <div slot="footer">
        <Button size="large" long @click="showDetailStatus = false"
          >确定</Button
        >
      </div>
    </Modal>

    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-show="showList">
      <desc-com :startDate="startDate" :endDate="endDate"></desc-com>

      <div class="listRankTitle">
        <div
          v-for="(item, key) in rankCycle"
          :key="key"
          @click="chooseRankType(item)"
          :class="item == defaultRankCycle ? 'banganStyAcvive' : ''"
        >
          {{ item }}
        </div>
      </div>

      <dept-tab-com
        v-if="defaultRankCycle.indexOf('单位') > -1"
        @chooseTab="chooseTabItem"
      ></dept-tab-com>
      <list-rank
        :needRankTitle="false"
        :columns="columns"
        :rankCycle="rankCycle"
        :defaultRankCycle="defaultRankCycle"
        :showList="showList"
        @chooseRankType="chooseRankType"
        :showLoad="showLoad"
        :comHeight="comHeight"
      ></list-rank>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import moment from "moment";

import { wxConPost, getRadio } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listRank from "../../components/listRank.vue";
import descCom from "../../components/descCom.vue";
import deptTabCom from "../../components/deptTabCom.vue";

export default {
  name: "jifenRank",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listRank,
    descCom,
    deptTabCom,
  },

  data() {
    return {
      isFilter: true,
      comHeight: 7,
      curTabItem: {},
      isShowSearch: false, // 是否显示搜索框

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级

      showDetailStatus: false,
      detailInfo: {}, // 单位详情

      showList: [],
      deptData: [],
      dataList: [],
      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      startDate: "",
      endDate: "",
      tongjiType: "单位周榜",
      rankCycle: ["单位周榜", "单位月榜", "个人周榜", "个人月榜"],
      defaultRankCycle: "单位周榜",
      columns: [
        "单位",
        {
          name: "总积分",
          value: "平台总积分",
        },
        "平均积分",
      ],
      sortList: [
        {
          name: "总积分",
          value: "平台总积分",
        },
        {
          name: "平均积分",
          value: "averageJifen",
        },
      ],
    };
  },
  methods: {
    getRankParam(type) {
      switch (type) {
        case "单位周榜":
          return "week_dept";
        case "单位月榜":
          return "month_dept";
        case "个人周榜":
          return "week_user";
        case "个人月榜":
          return "month_user";
        default:
          return "week_dept";
      }
    },
    chooseRankType(type) {
      this.defaultRankCycle = type;
      const _type = this.getRankParam(type);
      this.comHeight = _type.indexOf("user") > 1 ? 4 : 7;
      this.isFilter = _type.indexOf("user") > 1 ? false : true;
      this.getDeptStatus(_type);
    },

    chooseTabItem(tabItem) {
      this.curTabItem = tabItem;
      // 查找部门为 childDeptList
      this.initRankGroup(tabItem);
    },
    initRankGroup(tabItem) {
      // console.log("initRankGroup------", tabItem);
      if (!_.isEmpty(tabItem) && tabItem) {
        const dataList = [];
        for (const iterator of this.dataList) {
          if (_.indexOf(tabItem.childDeptList, iterator.deptId) > -1) {
            dataList.push(iterator);
          }
        }
        this.showList = dataList;
      } else {
        this.showList = this.dataList;
      }
    },

    async getDeptStatus(type) {
      try {
        this.showLoad = true;
        let _type = type || this.getRankParam();
        const _url = `/services/gttt-wxstats-mgt/integral/${_type}`;

        const ret = await wxConPost(_url);
        // console.log("----", _url, ret);
        if (ret.errMsg) {
          this.dailogshow = true;
          this.dailogoptions.content = ret.errMsg;
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
        this.showLoad = false;

        let deptData = _.get(ret, ["ret", "data"], []);
        let timeArea = _.get(ret, ["ret", "timeArea"], "");

        this.dealDateData(timeArea);
        if (_type.indexOf("user") > -1) {
          this.dealUserRankData(deptData);
        } else {
          this.dealDeptRankData(deptData);
          this.initRankGroup(this.curTabItem);
          this.getRadioDeptStatus(type);
        }
      } catch (error) {
        console.log(";------", error);
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },

    dealDeptRankData(data) {
      // 20-35岁职工: 0
      // 35-50岁职工: 0
      // 50以上的职工: 52
      // deptId: 39
      // 分享总积分: 476
      // 单位: "衡阳车务段"
      // 平台总积分: 3756
      // 总人数: 3232
      // 阅读总积分: 1325
      // 点赞总积分: 552
      // 签到总积分: 668
      // 视频阅读总积分: 154
      // 视频阅读时长总积分: 202
      // 评论总积分: 7
      // 阅读时长总积分: 372
      let dataList = [];
      for (const iterator of data) {
        if (iterator["单位"] && iterator["总人数"]) {
          const averageJifen = iterator["平台总积分"] / iterator["总人数"];
          dataList.push(
            Object.assign(iterator, {
              averageJifen,
              平均积分: averageJifen.toFixed(2),
              环比增长: "-",
            })
          );
        }
      }
      this.columns = [
        "单位",
        {
          name: "总积分",
          value: "平台总积分",
        },

        // "阅读总积分",
        "平均积分",
        {
          name: "环比增长",
          value: "环比增长",
        },
      ];
      this.dataList = _.reverse(_.sortBy(dataList, ["averageJifen"]));
    },

    // 处理人员数据
    dealUserRankData(data) {
      // 分享总积分: 20
      // 单位: "长沙电务段"
      // 姓名: "徐屹"
      // 平台总积分: 90
      // 阅读总积分: 12
      // 点赞总积分: 10
      // 签到总积分: 2
      // 视频阅读总积分: 12
      // 视频阅读时长总积分: 12
      // 评论总积分: 10
      // 车间（科室）: "电子设备车间"
      // 阅读时长总积分: 12
      let showList = [];
      for (const iterator of data) {
        const averageJifen = iterator["平台总积分"] / iterator["总人数"];
        showList.push(
          Object.assign(iterator, {
            平均积分: averageJifen.toFixed(2),
          })
        );
      }
      this.columns = ["姓名", "单位", "平台总积分"];
      this.showList = _.reverse(_.sortBy(showList, ["平台总积分"]));
    },
    //  "2022-03-21_2022-03-23"
    dealDateData(timeArea) {
      this.startDate = timeArea.slice(0, 10);
      this.endDate = timeArea.slice(11, 21);
    },

    // 处理环比
    async getRadioDeptStatus(type) {
      try {
        let _type = type || this.getRankParam();
        let _query = {
          date:
            _type.indexOf("week") > -1
              ? moment().subtract(1, "weeks").format("YYYY-MM-DD")
              : moment().subtract(1, "months").format("YYYY-MM-DD"),
        };
        const _url = `/services/gttt-wxstats-mgt/integral/${_type}`;

        const ret = await wxConPost(_url, _query);

        let deptData = _.get(ret, ["ret", "data"], []);
        for (const iterator of this.dataList) {
          // 计算环比差
          const _huanBiData = _.find(deptData, (o) => {
            return o.deptId == iterator.deptId;
          });
          if (_huanBiData) {
            let averageJifen = 0;
            if (_huanBiData["总人数"] && _huanBiData["总人数"] > 0) {
              averageJifen = _huanBiData["平台总积分"] / _huanBiData["总人数"];
            }
            // console.log("_huanBiData-----", _huanBiData, averageJifen);
            // 环比增长率=（本期数-上期数）/上期数×100%
            iterator["环比增长"] = getRadio(
              iterator["averageJifen"],
              averageJifen
            );
          }
        }
      } catch (error) {
        console.log(";------", error);
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },
    // 数据排序
    sortData(data) {
      console.log("data----", data);
      if (data && data.value) {
        if (data.order == "aes") {
          // 升序
          this.showList = _.sortBy(this.showList, [data.value]);
        } else {
          // 降序
          this.showList = _.reverse(_.sortBy(this.showList, [data.value]));
        }

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
  },
  async created() {
    this.getDeptStatus();
  },
};
</script>

<style scoped></style>
